body {
	font-size: 12px;
}
.topbar{
	background: #F59833;
}
.topbar .top-navbar {
    min-height: 60px;
	max-height: 65px;
}

.topbar .top-navbar .navbar-header {
	background: #fff;
	line-height: 65px;
}
.search-form button {
    position: absolute;
    top: 3px;
    right: 10px;
    color: #c3c3c3;
    font-size: 20px;
    border: 0;
    background: transparent;
}

.switch { position: relative; display: inline-block; width: 36px; height: 20px; margin-bottom: 0px;}
.switch input { display: none;}
.switch .slider.slider-round { border-radius: 26px;}
.switch .slider {position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; -webkit-transition: .4s; transition: .4s;}
input:checked + .slider:before { -webkit-transform: translateX(14px); -ms-transform: translateX(14px); transform: translateX(14px);}
.slider.slider-round:before { border-radius: 50%;}
.slider:before {position: absolute; content: ""; height: 14px; width: 14px; left: 4px; bottom: 3px; background-color: white; -webkit-transition: .4s; transition: .4s;}
input:checked + .slider { background-color: #7460ee;}
.dark-logo-icon {
	width: 50px;
}
.mini-sidebar .topbar .top-navbar .navbar-header {
	line-height: 39px;
}
.scroll-sidebar {
    padding-top: 10px;
}
.topbar .top-navbar .navbar-nav>.nav-item>.nav-link.sidebartoggler {
    font-size: 35px;
    line-height: 49px;
    color: #684a99 !important;
}
.sidebar-nav .has-arrow::after {
	width: .6em;
    height: .6em;
    border-width: 2px 0 0 2px;
	-webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
	border-color: #f59833;
}
.sidebar-nav .active>.has-arrow::after, .sidebar-nav li>.has-arrow.active::after, .sidebar-nav .has-arrow[aria-expanded="true"]::after {
    -webkit-transform: rotate(225deg) translate(0, -50%);
    -ms-transform: rotate(225deg) translate(0, -50%);
    -o-transform: rotate(225deg) translate(0, -50%);
    transform: rotate(225deg) translate(0, -50%);
}
.sidebar-nav>ul>li>a i, .sidebar-nav ul li a {
	color: #604294;
}
.sidebar-nav>ul>li>a.active, .sidebar-nav>ul>li>a:hover {
    border-left: 3px solid #f59833;
}
.sidebar-nav ul li a.active, .sidebar-nav ul li a:hover, .sidebar-nav>ul>li>a.active i, .sidebar-nav>ul>li>a:hover i, .sidebar-nav>ul>li.active>a i {
    color: #f59833;
}
.sidebar-nav>ul>li.active>a {
	 border-color:  #f59833;
	 color: #f59833;
}
.text-themecolor {
    color: #614394 !important;
}
.breadcrumb-item a {
    color: #614394;
}
.breadcrumb-item.active {
    color: #f59833;
}
/*.table thead th, .table th {
    text-align: center;
}*/
.stylish-table tbody td {
    vertical-align: middle;
    /*text-align: center;*/
}
.nav.nav-pills .nav-item {
	margin-left: 20px;
}
.nav-pills .nav-link {
    background: #e8e8e8;
    color: #f59833;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #f59833;
}
@media (min-width: 768px) {
	.dark-logo-icon {
		display:none;
	}
	.mini-sidebar .dark-logo-icon {
		display:block;
	}
}

.pagination { display: -webkit-box; display: -ms-flexbox; display: flex; list-style: none; border-radius: 0; padding:25px; margin: 0px;}
.pagination>li>a, .pagination>li>span{border:0px;padding: 3px 10px;border-radius: 4px;}
.pagination>li:first-child>a i, .pagination>li:first-child>span i, .pagination>li:last-child>a i, .pagination>li:last-child>span i{font-size:22px;color: #0057ff;}
   




/***********Yashpal CSS************/

.mini-sidebar .topbar .navbar-header .navbar-brand span {display: none;}
.top_header_home .topbar {position: fixed; top: 0px; width: 100%;}
.page-wrapper {padding-top: 65px;}
.card .card-body table tr td a img {width: 40px; height: 40px;}
.pagination.cus_pagination {padding: 0; justify-content: center;}
.pagination.cus_pagination li a {background: transparent; text-align: center; height: 40px; line-height: 30px;}
.pagination.cus_pagination li a span {font-size: 30px;}
.pagination.cus_pagination li a input {text-align: center;}

/**********/

.messages-list-box{}
.messages-list{}
.message{overflow: hidden;}
.message.message-in .message-body{background-color:rgba(130, 181, 60, 0.23); border: 1px solid #8bc34ab0;}
.message.message-out .message-body{background-color: #f1f1f1; margin-left: 20%; float: right;text-align: right;}
.messages-footer textarea.form-control{}
.message-body {
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    max-width: 80%;
    min-width: 60px;
    display: inline-block;
	float: left;
}
.message-body h4, .message-body h5{margin-top:0px; margin-bottom:3px; font-size: 16px;}
.message-body span{display:block; font-size:13px; margin-top:18px;}

/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';


.theme-green .bs-datepicker-head {
  background-color: #f3983e !important;
}

.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*="select-"]:after, .theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #f3983e !important;
}

.container-fluid{
  .table-container{
    .stylish-table{
      .mat-checkbox .mat-checkbox-inner-container{
        height: 23px !important;
        width: 23px !important;
      }
      .mat-checkbox-checked.mat-accent .mat-checkbox-background{background: #f3983e;}
    }
  }
}

.table td, .table th {
  padding: 0.3rem 0.3rem !important;
}

.label-sel{
  .mat-select-trigger{font-size: 17px;}
  .mat-form-field-flex{
    border: 1px solid #80808073;
    padding: 0 15px;
  }
  .mat-form-field-infix{padding: 0px !important;}
}
.cdk-overlay-pane{
  position: absolute !important;
  left: 37%;
  right: 37%;
  top: 30%;
  bottom: 50%;
}
.labelSelect .dropdown-menu.show{margin-left: -270px;}